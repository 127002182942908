import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { AxiosInstance as axios } from 'axios'
// import router from "../router"; // our vue router instance
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage:
      'https://www.softwebsolutions.com/wp-content/uploads/2020/08/health-insurance-industry.jpg',
    drawer: null,
    base_url: 'https://rodmuch.pythonanywhere.com',
    download_url: 'https://rodmuch.pythonanywhere.com/media/',
    // base_url: 'http://127.0.0.1:8000',
    refresh_token: "",
    access_token: "",
    loggedInUser: "",
    isAuthenticated: false,
    approved_logo:"",
    approved_color:"",
    is_ssb:false,
    is_finance:false,
    is_records:false,
    is_reception:false,
    is_admin:false,
    is_administrator:false,
    is_executive:false,
    is_agent:false,
    is_approver:false,
    institution:'',
    OrgId:'',
    branch:[],
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setRefreshToken: function(state, refreshToken) {
      state.refresh_token = refreshToken;
    },
    setAccessToken: function(state, accessToken) {
      state.access_token = accessToken;
    },
    // sets state with user information and toggles
    // isAuthenticated from false to true
    setAuthenticated: function(state){
      state.isAuthenticated = true;
    },
    setLoggedInUser: function(state, user) {
      state.loggedInUser = user;
    },
    setLogo: function(state, approved_logo){
      state.approved_logo = approved_logo;

    },
    setColor: function(state, approved_color) {
      state.approved_color = approved_color;
    },
    setIsSSB: function(state, is_ssb) {
      state.is_ssb = is_ssb;
    },
    setIsITAdmin: function(state, is_administrator) {
      state.is_administrator = is_administrator;
    },
    setIsExecutive: function(state, is_executive) {
      state.is_executive = is_executive;
    },
    setIsFinance: function(state, is_finance) {
      state.is_finance = is_finance;
    },
    setIsRecords: function(state, is_records) {
      state.is_records = is_records;
    },
    setIsReception: function(state, is_reception) {
      state.is_reception = is_reception;
    },
    setIsAgent: function(state, is_agent) {
      state.is_agent = is_agent;
    },
    setIsApprover: function(state, is_approver) {
      state.is_approver = is_approver;
    },
    setIsAdmin: function(state, is_admin) {
      state.is_admin = is_admin;
    },
    setinstitution: function(state, institution){
      state.institution = institution;
    },
    setOrgId: function(state, OrgId){
      state.OrgId = OrgId;
    },
    setbranch: function(state, branch){
      state.branch = branch;
    },
    // delete all auth and user information from the state
    clearUserData: function(state) {
      state.refresh_token = "";
      state.approved_color = "";
      state.approved_logo = "";
      state.access_token = "";
      state.loggedInUser = "";
      state.isAuthenticated = false;
      state.is_ssb = false;
      state.is_finance = false;
      state.is_reception = false;
      state.is_records = false;
      state.is_admin = false;
      state.is_bss = false;
      state.is_agent = false;
      state.is_approver = false;
      state.institution = "";
      state.OrgId = "";
      state.branch = "";
    },
  },
  actions: {
    refreshToken: async ({ state, commit }) => {
      const refreshUrl = "refresh/";
      try {
        await axios
            .post(refreshUrl, { refresh: state.refresh_token })
            .then(response => {
              if (response.status === 200) {
                commit("setAccessToken", response.data.access);
              }
            });
      } catch (e) {
        console.log(e.response);
      }
    },
    fetchUser: async ({ commit }) => {
      const currentUserUrl = "";
      try {
        await axios.get(currentUserUrl).then(response => {
          if (response.status === 200) {
            commit("setLoggedInUser", response.data);
          }
        });
      } catch (e) {
        console.log(e.response);
      }
    }
  },
  getters: {
    loggedInUser: state => state.loggedInUser,
    is_administrator : state => state.is_administrator,
    IsExecutive : state => state.is_executive,
    isAuthenticated: state => state.isAuthenticated,
    accessToken: state => state.access_token,
    refreshToken: state => state.refresh_token,
    approved_logo: state => state.approved_logo,
    approved_color: state => state.approved_color,
    is_ssb: state => state.is_ssb,
    is_finance: state => state.is_finance,
    is_records: state => state.is_records,
    is_reception: state => state.is_reception,
    is_admin: state => state.is_admin,
    is_agent: state => state.is_agent,
    is_approver: state => state.is_approver,
    is_bss: state => state.is_bss,
    institution: state => state.institution,
    OrgId: state => state.OrgId,
    branch: state => state.branch
  },
})
