<template>
  <v-app>
    <v-main class="auth-pages">
      <div
        class="header-auth position-relative ma-4 pb-12 pt-2 border-radius-xl"
        :style="`background-image:  url(${require('../../assets/img/curved-images/curved6.jpg')}; background-size: cover; background-position: 50%;`"
      >
        <span
          class="mask bg-gradient-default border-radius-xl opacity-9"
        ></span>
        <v-container>
          <v-row class="d-flex mt-5">
            <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
              <h1
                class="
                  text-h1 text-white
                  font-weight-bolder
                  text-center
                  mb-5
                  mt-2
                "
              >
                {{ headerTitle() }}
              </h1>
              <p
                class="
                  text-white
                  font-size-root
                  text-center
                  font-weight-thin
                  mb-12
                "
              >

              </p>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <v-container class="mt-n16 pb-0">
          <router-view></router-view>
          <content-footer auth v-if="!$route.meta.hideFooter"></content-footer>
        </v-container>
      </fade-transition>
    </v-main>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
    };
  },
  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "SignUpBasic":
          this.paragraphs =
            "Use these awesome forms to login or create new account in your project for free.";
          return "NEC - Construction";
        default:
          break;
      }
    },
  },
};
</script>
