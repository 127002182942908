<template>
  <span>
    <template v-if="auth">
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="8" class="mx-auto text-center">
                <p class="copyright text-secondary text-md ls-0">
                  Copyright © {{ new Date().getFullYear() }}

                  <a
                    href="#"
                    class="
                      text-decoration-none
                      btn-hover
                      text-primary text-body-2
                      ls-0
                    "
                    target="_blank"
                    >Mugonat Systems</a
                  >
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>

    <template v-else>
      <v-footer color="transparent" class="px-6">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" md="6">
                <div class="copyright text-body-2 ls-0 text-muted">
                  © {{ new Date().getFullYear() }},
                  <a
                    href="#"
                    class="
                      text-decoration-none text-typo text-body-2
                      ls-0
                      font-weight-bold
                    "
                    target="_blank"
                    >Mugonat Systems</a
                  >
                  for a better web.
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>
<script>
export default {
  name: "content-footer",
  props: {
    auth: Boolean,
  },
  data() {
    return {
      footer: [
        {
          linkName: "Website",
          link: "#",
        },
        {
          linkName: "About Us",
          link: "#",
        },
        {
          linkName: "News",
          link: "#",
        },
      ],
      icons: [
        {
          icon: "fab fa-dribbble",
        },
        {
          icon: "fab fa-twitter",
        },
        {
          icon: "fab fa-instagram",
        },
        {
          icon: "fab fa-pinterest",
        },
        {
          icon: "fab fa-github",
        },
      ],
    };
  },
};
</script>
