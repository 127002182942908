import store from "@/store";

export async function checkTokenStatus () {
  return fetch(store.state.base_url  + '/returns/get-batch-returns/', {
    method: 'GET',
    headers: {
      Authorization:
        `Bearer ${store.getters.accessToken}`,
    },
  })
    .then((response) => {
      return response.status
    })
    .catch((err) => {
      console.error(err)
    })
}

// ! needs attention from the Backend code.
// export async function refreshToken () {
//   return fetch(API + '/auth/token/refresh/', {
//     method: 'POST',
//     headers: {
//       Authorization:
//       `Bearer ${JSON.parse(localStorage.getItem('vuex')).access_token}`,
//     },
//   })
//     .then((response) => {
//       return response.status
//     })
//     .catch((err) => {
//       console.error(err)
//     })
// }
